
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

const Header = () => (
  <header className="text-center py-8">
    <img src="/logo.png" alt="The Silence Project" className="mx-auto w-48" />
    <h1 className="text-3xl font-semibold mt-4">The Silence Project</h1>
    <p className="text-lg mt-2 max-w-xl mx-auto">
      A crowdsourced path toward relief. Learn sound strategies, explore neuromodulation tools, and join our community to collaboratively heal tinnitus.
    </p>
  </header>
);

const Home = () => (
  <main className="px-4 py-8 max-w-4xl mx-auto text-center">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10">
      <CardLink title="Sound Management Lessons" to="/sound-management" />
      <CardLink title="Neuromodulation Tools" to="/neuromodulation" />
      <CardLink title="Community Discussion" to="/community" />
    </div>
  </main>
);

const CardLink = ({ title, to }) => (
  <Link to={to}>
    <div className="border p-6 rounded hover:shadow-lg transition-shadow duration-200 text-xl font-medium">{title}</div>
  </Link>
);

const TipJar = () => (
  <div className="text-center my-10">
    <p className="text-gray-600 mb-2">Support this work:</p>
    <a
      href="https://www.buymeacoffee.com/thesilenceproject"
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 hover:underline"
    >
      buymeacoffee.com/thesilenceproject
    </a>
  </div>
);

const SoundManagement = () => (
  <section className="px-4 max-w-3xl mx-auto">
    <h2 className="text-2xl font-bold mt-10 mb-4">Sound Management Lessons</h2>
    <p className="mb-6">
      Practical techniques to shape your environment and retrain your brain's response to sound.
    </p>
    <TipJar />
  </section>
);

const Neuromodulation = () => (
  <section className="px-4 max-w-3xl mx-auto">
    <h2 className="text-2xl font-bold mt-10 mb-4">Neuromodulation Tools</h2>
    <p className="mb-6">
      Explore interactive web-based tools designed to nudge neural pathways into healthier rhythms.
    </p>
    <div className="my-6 p-4 border border-gray-300 rounded-lg">
      {/* Placeholder for web app */}
      <p className="italic text-gray-500">[Web App Coming Soon]</p>
    </div>
    <TipJar />
  </section>
);

const Community = () => (
  <section className="px-4 max-w-3xl mx-auto">
    <h2 className="text-2xl font-bold mt-10 mb-4">Community Discussion</h2>
    <p className="mb-6">
      Join others navigating tinnitus—share your story, ask questions, and collaborate on strategies.
    </p>
    <TipJar />
  </section>
);

const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sound-management" element={<SoundManagement />} />
      <Route path="/neuromodulation" element={<Neuromodulation />} />
      <Route path="/community" element={<Community />} />
    </Routes>
  </Router>
);

export default App;
